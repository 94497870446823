<script setup>
  import { ref, onBeforeMount, onMounted, watch } from 'vue';
  import { useForm } from '@tanstack/vue-form';
  import AddressesDialog from "../../../components/Utility/Dialogs/Addresses.vue";

  import { useAddressesStore } from "../../../stores/addresses";

  import { useDialog } from "../../../composables/useDialog.js";
  import { useSetNewSwipers } from '../../../composables/useSetNewSwipers.js';
  import { validatePhoneNumber } from '../../../includes/validatePhoneNumber.js';

  const setNewSwipers = useSetNewSwipers();

  const props = defineProps({
    data: Object
  });

  const emit = defineEmits(['success']);
  
  // const editAddress = ref([]);

  const formAddresses = ref({
    shipping: null,
    billing: null,
  });

  const sameAsShipping = ref(true);

  const initializedSwipers = ref(false);

  const form = useForm({
    defaultValues: {
      phone_number: '',
      track_and_trace_email: '',
      invoice_number: '', 
    },
    onSubmit: async ({ value }) => {
      if (sameAsShipping.value) {
        formAddresses.value.billing = formAddresses.value.shipping;
      }

      const data = {
        phone_number: value.phone_number,
        addresses: formAddresses.value,
        track_and_trace_email: value.track_and_trace_email,
        invoice_number: value.invoice_number,
      }

      fetch('/api/checkout/address', {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          emit('success',true);
        } else {
          // Handle error
        }
      })
      .catch(error => {
        // Handle error
      });
    }
  });

  const setAddress = (id, type) => {
    if (formAddresses.value[type]?.id !== id) {
      formAddresses.value[type] = { id };
    }
  }

  const openAddressesDialog = async () => {
    const isMobile = ref(false);

    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    useDialog().open(AddressesDialog, {'cartAddresses': formAddresses, 'addresses': useAddressesStore().addresses, 'countries': useAddressesStore().countries});
  };

  const findCountryById = (country_id) => {
    return props.data.countries.find((country) => country.id == country_id);
  }  

  
  onBeforeMount(async () => {
    useAddressesStore().fillPrimaryStores();
    useAddressesStore().fillCountries();
  });
  
  onMounted(() => {
    if (props.data.cart.shipping_address?.external_id) {
      formAddresses.value.shipping = {"id" : props.data.cart.shipping_address?.external_id};
    }

    if (props.data.cart.billing_address?.external_id) {
      formAddresses.value.billing = {"id" : props.data.cart.billing_address?.external_id};
    }
    
    if (props.data.cart.billing_address?.external_id !== props.data.cart.shipping_address?.external_id) {
      sameAsShipping.value = false;
    }


    setNewSwipers();
  });

  watch(
    () => useAddressesStore().isAddressesStoreFilled,
    (newVal) => {
      if (newVal) {
        setNewSwipers();
      }
    }
  );
</script>
<template>
  <section class="checkout-existing-address" aria-label="Vul hier je gegevens in">
    <span class="checkout-existing-address__title">
      Bezorgadres <!-- HARDCODED TEXT -->
    </span>
    <div class="slider slider--shipping-addresses" v-if="useAddressesStore()?.isAddressesStoreFilled === true && useAddressesStore()?.isLoggedIn === true">
      <div class="slider__items">
          <div class="slider__swiper swiper swiper--buttons swiper--vue" :class="{ 'swiper--slide-active' : useAddressesStore()?.addresses.length > 2 }">
              <div class="slider__swiper-wrapper swiper-wrapper">
                  <div class="swiper-slide" v-for="(address, index) in Array.isArray(useAddressesStore()?.addresses) ? useAddressesStore()?.addresses : []" :key="index" :class="{'active-slide' : address.id === formAddresses?.shipping?.id}" :data-nr="index">
                    <div class="address__content"
                          @click="setAddress(address.id, 'shipping')"
                          :class="{'address__content--active': address.id === formAddresses?.shipping?.id}"
                    >
                      <div class="address__inner">
                        <strong class="notranslate">{{ address.title }}</strong>
                        <span class="notranslate">{{ address.first_name }} {{ address.last_name }}</span>
                        <span class="notranslate">{{ address.street }} {{ address.house_number }} {{ address.house_number_addition }}</span>
                        <span class="notranslate">{{ address.zip }} {{ address.city }}</span>
                        <span v-if="address?.country_id">{{ findCountryById(address.country_id).name }}</span>
                        <span v-else-if="address?.country">{{ address.country }}</span>
                      </div>
                    </div>
                  </div>
              </div>
            <div class="slider__buttons swiper__buttons">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
          </div>
      </div>
    </div>
    <div class="slider--skeleton" v-else><!-- --></div>
    <button class="button__change-address btn-text--primary-600" @click="openAddressesDialog(useAddressesStore().addresses)" v-if="useAddressesStore()?.isAddressesStoreFilled === true && useAddressesStore()?.isLoggedIn === true">Wijzig of voeg nieuw adres toe</button>
    <span class="button--skeleton" v-else><!-- --></span>
    <span class="slider--skeleton" v-if="useAddressesStore()?.isAddressesStoreFilled === false"><!-- --></span>
    <div class="input input--single" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
      <div class="input-inner">
        <span for="invoice_same_as_shipping" class="form__label">Factuuradres</span>
        <div class="input-inner__checkbox">
          <label for="invoice_same_as_shipping" class="input-container">
            <form.Field name="invoice_same_as_shipping">
              <template v-slot="{ field }">
                <input 
                  type="checkbox"
                  :name="field.name"
                  :value="field.state.value"
                  :checked="sameAsShipping"
                  @blur="field.handleBlur"
                  id="invoice_same_as_shipping"                 
                  @input="field.handleChange($event.target.checked); sameAsShipping = $event.target.checked"
                  class="checkbox__fix-input"
                >
                <span class="checkbox__fix-checkbox"></span>
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            Hetzelfde als bezorgadres</label>
        </div>
      </div>
    </div>
    <div class="slider slider--billing-addresses" :class="{'slider--inactive' : sameAsShipping}" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
      <div class="slider__items">
          <div class="slider__swiper swiper swiper--buttons swiper--vue" :class="{ 'swiper--slide-active' : useAddressesStore()?.addresses.length > 2 }">
              <div class="slider__swiper-wrapper swiper-wrapper">
                  <div class="swiper-slide" v-for="(address, index) in useAddressesStore()?.addresses" :key="index" :class="{'active-slide' : address.id === formAddresses?.shipping?.id}" :data-nr="index">
                    <div class="address__content"
                          @click="setAddress(address.id, 'billing')"
                          :class="{'address__content--active': address.id === formAddresses?.billing?.id}"
                    >
                      <div class="address__inner">
                        <strong class="notranslate">{{ address.title }}</strong>
                        <span class="notranslate">{{ address.first_name }} {{ address.last_name }}</span>
                        <span class="notranslate">{{ address.street }} {{ address.house_number }} {{ address.house_number_addition }}</span>
                        <span class="notranslate">{{ address.zip }} {{ address.city }}</span>
                        <span>{{ address.country }}</span>
                      </div>
                    </div>
                  </div>
              </div>
            <div class="slider__buttons swiper__buttons">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
          </div>
      </div>
    </div>
    <span class="checkout-existing-address__title checkout--phonenumber">
      Contactgegevens <!-- HARDCODED TEXT -->
    </span>
    <div class="form__wrapper">
      <form @submit="
      (e) => {
        e.stopPropagation()
        e.preventDefault()
        form.validate()
        form.handleSubmit()
      }
      "
      method="post"
      > 
        <div class="input input--single input--full">
          <div class="input-inner" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
            <label for="phone_number" class="form__label">Telefoonnummer*</label>
            <form.Field 
              name="phone_number"
              :validators="{
                onBlur: ({ value }) => {
                  switch (true) {
                  case !value:
                    return 'Dit veld is verplicht';
                  case !validatePhoneNumber(value):
                    return 'Ongeldig telefoonnummer';
                  default:
                    return null;
                  }
                },
              }"
            >
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner" v-else>
            <span class="form-label--skeleton"><!-- --></span>
            <span class="form-input--skeleton"><!-- --></span>
          </div>   
        </div>
        <div class="input input--single">
          <div class="input-inner" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
            <label for="track_and_trace_email" class="form__label">Track & Trace e-mailadres</label>
            <form.Field name="track_and_trace_email">
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner" v-else>
            <span class="form-label--skeleton"><!-- --></span>
            <span class="form-input--skeleton"><!-- --></span>
          </div>   
        </div>
        <div class="input input--single">
          <div class="input-inner" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
            <label for="invoice_number" class="form__label">Inkoopnummer</label>
            <form.Field name="invoice_number">
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner" v-else>
            <span class="form-label--skeleton"><!-- --></span>
            <span class="form-input--skeleton"><!-- --></span>
          </div>
        </div>
        <div class="form__submit">
          <div class="form__submit-inner">
            <div class="form__submit-button">
              <button class="btn-solid--primary-600" :class="{disabled : !formAddresses.shipping}" type="submit">
                Door naar betaalwijze  <!-- HARDCODED TEXT -->
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>  
  </section>
</template>


<style lang="scss" scoped>
	@import '../../../../sass/elements/skeletons.scss';

  .slider--skeleton {
    @apply h-[162px] w-full mt-3;
    @extend .skeleton;
  }

  .address--skeleton {
    @apply h-4 w-[140px];
    @extend .skeleton;

    + .address--skeleton {
      @apply mt-3;
    }
  }

  .form-label--skeleton {
    @apply h-5 w-[100px] mb-2;
    @extend .skeleton;
  }

  .form-input--skeleton {
    @apply h-[51px] w-[390px];
    @extend .skeleton;
  }

  .button--skeleton {
    @apply h-5 w-[200px] mt-3 mb-9;
    @extend .skeleton;
  }

  .checkout-existing-address {
      @apply col-span-8;
      @apply md:col-span-12;
  }

  .checkout-existing-address__title {
      @apply font-bold text-24_28_18_26 text-dark col-span-12 block;

      &--small {
        @apply text-17_21_14_21;
      }
  }

  .address__content {
    @apply bg-white py-8 px-10 rounded border border-solid border-border transition-all duration-300 cursor-pointer h-full text-16_28_14_24;
    @apply sm:p-4;
    @apply hover:border-[#CCA666];

    &--active {
      @apply bg-[#FAF2E6] border-[#CCA666];
    }
  }

  .address__inner {
    @apply flex flex-col;
  }

  .button__change-address {
    @apply font-normal text-15_20_14_20 mb-9;
    @apply md:mb-2;
  }

  .form__wrapper {
    @apply mt-3 grid grid-cols-12;

    .input--single {
      @apply col-span-12 grid grid-cols-12;

      .input-inner {
        @apply col-span-6;
        @apply sm:col-span-12;
      }
    }

    form {
      @apply col-span-12 grid grid-cols-12;
    }
  }

  .form__submit {
    @apply border-t border-[#E0E0E0] border-solid col-span-12 pt-[30px] mt-12;
    @apply md:mt-6;
    @apply sm:border-none sm:pt-0;
    @apply mob:mt-[18px];
  }

  .form__submit-inner {
    @apply flex justify-between items-center;
    @apply sm:flex-col sm:items-start;
  }

  .checkout--phonenumber {
    @apply mt-12;
    @apply md:mt-[18px];
  }

  .input--same-as-shipping {
    @apply py-3;
    @apply md:pt-1;
  }

  .form__submit-button {
    @apply w-full flex justify-end;
    @apply mob:w-full;

    button {
      @apply cursor-pointer;

      &.disabled {
        @apply pointer-events-none;
      }
    }
  }

  .slider--inactive {
    @apply hidden;
  }

  .input {
    + .input {
      @apply mt-3;
      @apply sm:mb-0;
    }
  }
</style>
