<!-- App.vue -->
<script setup>
import { useForm } from '@tanstack/vue-form'
import { ref } from 'vue'
import { validatePhoneNumber } from '../../../includes/validatePhoneNumber';

const findCountry = (id) => {
  return props.data?.countries.find((country) => country.id == id);
}

const buttonLoading = ref(false);

const props = defineProps({
  data: Object,
  privacyUrl: String,
  tocUrl: String
});

const emit = defineEmits(['success, go']);
const togglePasswordFields = ref(false);
const invoiceSameAsShipping = ref(true);

const handleInvoiceSameAsShipping = () => {
  invoiceSameAsShipping.value = !invoiceSameAsShipping.value;
}; 

const countries = ref(props.data?.countries);

const form = useForm({
  defaultValues: {
    first_name: props.data?.cart?.shipping_address?.first_name ?? '',
    last_name: props.data?.cart?.shipping_address?.last_name ?? '',
    company_name: props.data?.cart?.shipping_address?.company ?? '',
    phone_number: props.data?.cart?.shipping_address?.phone_number ?? '',
    email: props.data?.cart?.shipping_address?.email_address ?? '',
    account_creation: false,
    invoice_same_as_shipping: true,
    account: {
      password: '',
      password_confirmation: '',
    },
    addresses: {
      shipping: {
        street: props.data?.cart?.shipping_address?.street ?? '',
        number: props.data?.cart?.shipping_address?.house_number ?? '',
        addition: props.data?.cart?.shipping_address?.house_number_addition ?? '',
        postal_code: props.data?.cart?.shipping_address?.postal_code ?? '',
        city: props.data?.cart?.shipping_address?.city ?? '',
        country_id: props.data?.cart?.shipping_address?.country_id ?? ''
      },
      billing: {
        street: props.data?.cart?.billing_address?.street ?? '',
        number: props.data?.cart?.billing_address?.house_number ?? '',
        addition: props.data?.cart?.billing_address?.house_number_addition ?? '',
        postal_code: props.data?.cart?.billing_address?.postal_code ?? '',
        city: props.data?.cart?.billing_address?.city ?? '',
        country_id: props.data?.cart?.billing_address?.country_id ?? ''
      },
    },
    privacy_statement: false,
    password_match: false,
  },
  onSubmit: async ({ value }) => {
    // Do something with form data
    
    // if (value.account_creation) {
    //   if (value.account.password !== value.account.password_confirmation) {
    //     form.setFieldError('account.password', 'Wachtwoorden komen niet overeen');
    //     form.setFieldError('account.password_confirmation', 'Wachtwoorden komen niet overeen');
    //   }
    // }
    
    if (value.invoice_same_as_shipping) {
      value.addresses.billing = value.addresses.shipping;
    }
    buttonLoading.value = true;
    fetch('/api/checkout/address', {
      method: 'POST',
      body: JSON.stringify(value)
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          emit('success',true);
          buttonLoading.value = false;
        } else {
          // TODO Handle error
          buttonLoading.value = false;
          
        }
      })
      .catch(error => {
        // Handle error
      });
  },
})
</script>

<template>
  <section class="checkout-address" aria-label="Vul hier je gegevens in">
    <span class="checkout-address__title">
      Persoonlijke gegevens
    </span>
    
    <div class="form__wrapper">
      <form @submit="
      (e) => {
        e.stopPropagation()
        e.preventDefault()
        form.validate()
        form.handleSubmit()
      }
      "
      method="post"
      >  
        <div class="input">
          <div class="input-inner">
            <label for="first_name" class="form__label">Voornaam*</label>
            <form.Field 
              name="first_name"
              :validators="{
                onChange: ({ value }) =>
                  !value
                    ? `Dit veld is verplicht`
                    : undefined,
                }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input">
          <div class="input-inner">
            <label for="last_name" class="form__label">Achternaam*</label>
            <form.Field 
              name="last_name"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
              >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="company_name" class="form__label">Bedrijfsnaam*</label>
            <form.Field 
              name="company_name"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="phone_number" class="form__label">Telefoonnummer*</label>
            <form.Field 
              name="phone_number"
              :validators="{
                onBlur: ({ value }) => {
                  switch (true) {
                  case !value:
                    return 'Dit veld is verplicht';
                  case !validatePhoneNumber(value):
                    return 'Ongeldig telefoonnummer';
                  default:
                    return null;
                  }
                },
              }"
            >
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="email" class="form__label">Email*</label>
            <form.Field 
              name="email"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="email"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <span for="account" class="form__label">Account aanmaken</span>
            <div class="input-inner__checkbox">
              <label for="account_creation" class="input-container">
                <form.Field name="account_creation">
                  <template v-slot="{ field }">
                    <input 
                      type="checkbox"
                      :name="field.name"
                      :value="field.state.value"
                      @blur="field.handleBlur"
                      id="account_creation"                 
                      @input="field.handleChange($event.target.checked); togglePasswordFields = $event.target.checked"
                      class="checkbox__fix-input"
                    >
                    <span class="checkbox__fix-checkbox"></span>
                  </template>
                </form.Field>
                Ik wil een account aanmaken</label>
            </div>
          </div>
        </div>

        <div class="input input--single" v-if="togglePasswordFields">
          <div class="input-inner">
            <label for="password" class="form__label">Wachtwoord</label>
            <form.Field 
              name="account.password"
              :validators="{
                onSubmit: ({ value }) => !value && togglePasswordFields ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="password"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': togglePasswordFields && field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="togglePasswordFields && field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner">
            <label for="password" class="form__label">Herhaal wachtwoord</label>
            <form.Field 
              name="account.password_confirmation"
              :validators="{
                onSubmit: ({ value }) => !value && togglePasswordFields ? 'Dit veld is verplicht' : null,
                onChangeListenTo: ['account.password'],
                onChange: ({ value, fieldApi }) => {
                          if (value !== fieldApi.form.getFieldValue('account.password')) {
                              return 'Wachtwoorden komen niet overeen'
                          }
                          return undefined
                        }
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="password"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>

        <span class="checkout-address__title">
          Adresgegevens
        </span>

        <div class="input input--single">
          <div class="input-inner">
            <label for="country" class="form__label">Land</label>
            <form.Field name="addresses.shipping.country_id">
              <template v-slot="{ field }">

                <select
                  :name="field.name"
                  :value="field.state.value"
                  @blur="field.handleBlur"
                  @input="(e) => {
                    field.handleChange(e.target.value);
                  }"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                >
                  <option value="" disabled>Selecteer een land</option>
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                  <!-- Add more countries here -->
                </select>
              </template>
            </form.Field>
          </div>
        </div>

        <div class="input input--address">
          <div class="input-inner input-inner--col-6">
            <label for="address" class="form__label">Straatnaam</label>
            <form.Field 
              name="addresses.shipping.street"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner">
            <label for="address-number" class="form__label">Huisnr.</label>
            <form.Field 
              name="addresses.shipping.number"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner">
            <label for="address-addition" class="form__label">Toevoeging</label>
            <form.Field 
              name="addresses.shipping.addition"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--city">
          <div class="input-inner ">
            <label for="address-postal" class="form__label">Postcode</label>
            <form.Field 
              name="addresses.shipping.postal_code"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner input-inner--col-6">
            <label for="address-city" class="form__label">Plaatsnaam</label>
             <form.Field 
              name="addresses.shipping.city"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>

        <div class="input input--single">
          <div class="input-inner">
            <span for="invoice_same_as_shipping" class="form__label">Factuuradres</span>
            <div class="input-inner__checkbox">
              <label for="invoice_same_as_shipping" class="input-container">
                <form.Field name="invoice_same_as_shipping">
                  <template v-slot="{ field }">
                    <input 
                      type="checkbox"
                      :name="field.name"
                      :value="field.state.value"
                      :checked="invoiceSameAsShipping"
                      @blur="field.handleBlur"
                      id="invoice_same_as_shipping"                 
                      @input="field.handleChange($event.target.checked); invoiceSameAsShipping = $event.target.checked"
                      class="checkbox__fix-input"
                    >
                    <span class="checkbox__fix-checkbox"></span>
                  </template>
                </form.Field>
                Hetzelfde als bezorgadres</label>
            </div>
          </div>
        </div>
        <div class="billing-address" v-if="!invoiceSameAsShipping">
          <div class="input input--single">
            <div class="input-inner">
              <label for="country" class="form__label">Land</label>
                <form.Field name="addresses.billing.country_id">
                <template v-slot="{ field }">
                  <select
                    :name="field.name"
                    :value="field.state.value"
                    @blur="field.handleBlur"
                    @input="(e) => field.handleChange(e.target.value)"
                    :class="{'input--invalid': field.state.meta.errors.length > 0}"
                    required
                  >
                    <option value="" disabled>Selecteer een land</option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                    <!-- Add more countries here -->
                  </select>
                </template>
              </form.Field>
            </div>
            
          </div>

          <div class="input input--address">
            <div class="input-inner input-inner--col-6">
              <label for="address" class="form__label">Straatnaam</label>
              <form.Field 
              name="addresses.billing.street"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            </div>
            <div class="input-inner">
              <label for="address-number" class="form__label">Huisnr.</label>
              <form.Field 
              name="addresses.billing.number"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            </div>
            <div class="input-inner">
              <label for="address-addition" class="form__label">Toevoeging</label>
              <form.Field 
              name="addresses.billing.addition"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            </div>
          </div>

          <div class="input input--city">
            <div class="input-inner ">
              <label for="address-postal" class="form__label">Postcode</label>
              <form.Field 
              name="addresses.billing.postal_code"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            </div>
            <div class="input-inner input-inner--col-6">
              <label for="address-city" class="form__label">Plaatsnaam</label>
              <form.Field 
              name="addresses.billing.city"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            </div>
          </div>
        </div>

        <div class="input input--single input--full">
          <div class="input-inner">
            <div class="input-inner__checkbox input-inner__checkbox--with-error">
              <label for="privacy_statement" class="input-container">
                <form.Field name="privacy_statement"
                  :validators="{
                    onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
                  }">                  
                  <template v-slot="{ field }">
                    <div>
                      <input 
                        type="checkbox"
                        :name="field.name"
                        :value="field.state.value"
                        @blur="field.handleBlur"
                        id="privacy_statement"               
                        @input="field.handleChange($event.target.checked)"
                        class="checkbox__fix-input"
                        :class="{'input--invalid': field.state.meta.errors.length > 0}"
                        required
                      >
                      <span class="checkbox__fix-checkbox"></span>
                      <span>Ik ga akkoord met de <a :href="privacyUrl" target="_blank">privacy statement</a> en <a :href="tocUrl" target="_blank">algemene voorwaarden</a> van Maunt en de verwerking van mijn gegevens.</span>
                    </div>
                    <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
                  </template>
                </form.Field>
                </label>
            </div>
          </div>
        </div>

        <div class="form__submit">
          <div class="form__submit-inner">
<!--            TODO, reset alreadyLoggedIn-->
            <div class="form__submit-login">Heb je al een account? <a @click="emit('go', '/api/checkout/login')" class="btn-text--primary-600">Inloggen op jouw account</a></div>
            <div class="form__submit-button">
              <button type="submit" class="btn-solid--primary-600" :class="{'loading': buttonLoading}">
                Door naar betaalwijze
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>
    
  <style lang="scss">
    .checkbox__fix-checkbox {
      @apply inline-flex flex-[0_0_36px] h-9 w-9 border border-solid border-[#E0E0E0];
      @apply mob:flex-[0_0_28px] mob:h-7 mob:w-7;
      // &::after {
      //   @apply font-fontAwesome text-black text-15_36_14_18 content-[\f00c] hidden;
      // }
    }
    .checkbox__fix-input {
      @apply absolute opacity-0 cursor-pointer h-9 w-9;
      @apply mob:w-7 mob:h-7;
      &:checked ~ .checkbox__fix-checkbox {
        @apply bg-[#FAF2E6] border-[#CCA666] flex items-center justify-center;
        &::after {
          @apply font-fontAwesome text-black text-15_36_14_18 content-["\f00c"] inline-flex;
        }
      }

      &.input--invalid {
        ~ .checkbox__fix-checkbox {
          @apply border-red-500;
        }
      }
    }
    
    .form__label {
      @apply mob:mb-1;
    }
    
    .input-inner__checkbox {
      label {
        @apply flex items-center gap-x-3 text-15_36_14_18;
        @apply hover:cursor-pointer;
        @apply mob:gap-x-2;
      }
    }

    .input-inner__checkbox--with-error {
      > label {
        @apply flex flex-col items-start;

        span[role="alert"] {
          @apply mt-1;
        }

        > div {
          @apply flex items-center gap-x-3 text-15_36_14_18;
          
          a {
            @apply text-primary-600;
            @apply hover:text-primary-700;
          }
        }
      }
    }
    
    .password__field-icon {
      @apply absolute right-6 top-1/2 -translate-y-1/2;
    }
  </style>
  <style lang="scss" scoped>
  .checkout-address {
    @apply col-span-8;
    @apply md:col-span-12;
  }
  
  .checkout-address__title {
    @apply font-bold text-24_28_18_26 text-dark mb-6 col-span-12 block;
    @apply sm:mb-4;
  }
  
  .form__wrapper {
    form {
      @apply grid grid-cols-12 gap-x-3;
      @apply sm:gap-x-1;
    }
  }
  
  .password__field {
    @apply relative;
  }

  input {
    &.input--invalid {
      @apply border-red-500;
    }
  }
  
  .input {
    @apply col-span-6;
    
    &:not(:last-child) {
      @apply mb-6;
      @apply sm:mb-3;
    }
    
    &:has(+ .checkout-address__title) {
      @apply mb-[60px];
      @apply md:mb-9;
      @apply sm:mb-6;
    }
    
    &.input--full {
      @apply mb-[30px];
      @apply mob:mb-0;
    }
    
    .input-inner {
      @apply col-span-12;
    }
    
    .form__checkbox {
      span {
        @apply block;
      }
      
      label {
        @apply text-15_24_13_18 text-black font-normal;
      }
    }
  }
  
  .input--single {
    @apply col-span-12 grid grid-cols-12 gap-x-3;
    @apply sm:gap-x-1;
    
    .input-inner {
      @apply col-span-6;
      @apply sm:col-span-12;
    }
    
    &:has(+.input--full) {
      @apply mb-12;
      @apply md:mb-9;
      @apply sm:mb-6;
    }
  }
  
  .billing-address {
    @apply col-span-12;
    
    .input--city {
      @apply mb-12;
      @apply md:mb-9;
      @apply sm:mb-6;
    }
  }
  
  .input--address, .input--city {
    @apply col-span-12 grid grid-cols-12 gap-x-3;
    @apply sm:gap-x-1;
    
    .input-inner {
      @apply col-span-3;
      
      &.input-inner--col-6 {
        @apply col-span-6;
      }
    }
  }
  
  .input--city {
    .input-inner {
      &.input-inner--col-6 {
        @apply sm:col-span-9;
      }
    }
  }
  
  .input--full {
    @apply col-span-12;
    
    .input-inner {
      @apply col-span-12;
    }
  }
  
  .form__submit {
    @apply border-t border-[#E0E0E0] border-solid col-span-12 pt-7;
    @apply sm:border-none sm:pt-0;
  }
  
  .form__submit-inner {
    @apply flex justify-between items-center;
    @apply sm:flex-col sm:items-start;
  }
  
  .form__submit-login {
    @apply text-15_36_14_18;
    a {
      @apply font-normal text-15_36_14_18 text-[#BF8A30];
      @apply xs:w-auto xs:inline-flex;
      @apply hover:text-primary-700;
    }
  }
  
  .form__submit-button {
    @apply hover:cursor-pointer;
    @apply mob:w-full;
  }
</style>
