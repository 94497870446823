<script setup>
  import { ref, onMounted, onUnmounted, onBeforeMount } from 'vue';
  import { useCartStore } from "../../stores/cart";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const cartStore = useCartStore();
  const cartAsideFooterRef = ref(null);

  const handleScroll = () => {
    if (window.matchMedia("(max-width: 900px)").matches) {
      if (cartAsideFooterRef.value) {
        const rect = cartAsideFooterRef.value.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if rect.bottom is at or below the bottom of the viewport
        if (rect.bottom >= windowHeight) {
          cartAsideFooterRef.value.classList.remove('cart__aside-footer--no-shadow');
        } else {
          cartAsideFooterRef.value.classList.add('cart__aside-footer--no-shadow');
        }
      }
    }
  };

  onMounted(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
</script>

<template>
<div class="cart__aside-footer" ref="cartAsideFooterRef">
  <div class="cart__aside-button" v-if="!cartStore.cartIsLoading">
    <!-- TODO HARCODED -->
    <a :href="appendLanguagePrefixURL('/afrekenen')" class="btn-solid--primary-600" :class="{ 'button--disabled' : cartStore.getTotalCartPrice == 0 }">Verder naar bestellen</a>
  </div>
  <div class="cart__aside-button cart__aside-button--skeleton" v-else>
    <!-- TODO HARCODED -->
    <span><!-- --></span>
  </div>

  <div class="cart__aside-logos" v-if="cartStore.cartLogos.length != 0">
    <img v-for="logo in cartStore.cartLogos" :src="logo" :key="logo" />
  </div>
  <div class="cart__aside-logos" v-else>
    <span class="cart__aside-logos--skeleton"><!-- --></span>
  </div>
</div>
</template>

<style lang="scss" scoped>
@import '../../../sass/elements/skeletons.scss';

.cart__aside-footer {
    @apply px-9;
    @apply md:px-0 md:-mx-6 md:bg-white md:shadow-cartFooter md:pt-3;
    @apply sm:-mx-4;

    &.cart__aside-footer--no-shadow {
      @apply shadow-none;
    }
}

.cart__aside-button{
    @apply w-full flex justify-center;
}

.cart__aside-button {
    @apply pb-6;
    @apply md:pb-3;

    .button--disabled {
        @apply opacity-50 cursor-not-allowed pointer-events-none;
    }
}

.btn-solid--primary-600 {
    @apply w-full;
    @apply md:max-w-[350px];
}

.cart__aside-logos {
    @apply pb-6 flex justify-center gap-x-6 gap-y-2;
    @apply md:pb-3;

    img {
        @apply h-8 w-8 flex-[0_0_36px] object-contain grayscale;
    }
}

.cart__aside-button--skeleton {
  @apply w-[350px] h-[54px] flex mx-auto mt-0.5 mb-6 pb-0;
  @apply md:h-[40px] md:mt-0;
  @extend .skeleton;
}

.cart__aside-logos--skeleton {
  @extend .skeleton;
  @apply h-8 w-full;
  @apply md:mt-4;
}
</style>
