<script setup>
  import { ref, onMounted } from "vue";
  import { useDialog } from "../../../composables/useDialog";
  import { useForm } from '@tanstack/vue-form';
  import { validateMail } from "../../../includes/validateMail";

  const form = useForm({
    initialValues: {
      username: '',
    },
    onSubmit: async ({ value }) => {
      const data = new FormData();
      data.append('username', value.username);
      data.append('vue', true);

      fetch('/forgot-password/submit', {
      method: 'POST',
      body: data
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          window.location = response.url;
        } else {

          // error cant be trigger because we don't want the user to know if the email is valid
        }
      })
      .catch(error => {
        // Handle error
      });
    }
  });

  onMounted(() => {
    const isMobile = ref(false);
    
    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }
  });
</script>
<template>
    <div class="forgot-password__dialog">
      <div class="dialog__header">
        <span class="dialog__title">Wachtwoord vergeten?</span> <!-- TO DO HARDCODED TEXT -->
        <p>
          Vul hier jouw e-mailadres in en we versturen je de mogelijkheid om een nieuw wachtwoord aan te maken.
        </p>
        <button class="dialog__close" @click="useDialog().toggle">
          <i class="fa-solid fa-times"></i>
        </button>
      </div>
      <div class="dialog__content form__wrapper">
        <form @submit="
        (e) => {
          e.stopPropagation()
          e.preventDefault()
          form.handleSubmit()
        }
        "
        method="post"
        > 
          <div class="form__wrapper-input form__wrapper-input--user">
            <form.Field 
              name="username"
              :validators="{
                onBlur: ({ value }) => {
                  switch (true) {
                  case !value:
                    return 'Dit veld is verplicht';
                  case !validateMail(value):
                    return 'Vul een geldig emailadres in';
                  default:
                    return null;
                  }
                },
              }"
            >
              <template v-slot="{ field }">
                <input
                  :name="field.name"
                  :value="field.state.value"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  type="email"
                  placeholder="E-mailadres"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="form__wrapper-input form__wrapper-input--user">
            <form.Field name="vue">
              <template v-slot="{ field }">
                <input
                  :name="field.name"
                  :value="field.state.value"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  type="checkbox"
                  placeholder="E-mailadres"
                  class="none"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="form__wrapper-button">
            <button type="submit" class="btn-solid--primary-600">Wachtwoord herstellen</button>
          </div>
        </form>
      </div>
    </div>
</template>
<style lang="scss" scoped>
  dialog {
    &[open] {
      @apply container grid grid-cols-12 gap-6 bg-transparent h-[90vh] p-0;
      @apply lg:px-6;
      @apply sm:px-4 sm:h-screen;
      @apply xs:p-0 xs:m-0 xs:max-w-full xs:h-full xs:max-h-full;

      &:focus-within {
        @apply outline-none;
      }

      &::backdrop {
        @apply bg-black/60;
        backdrop-filter: blur(2px);
      }
    }
  }

  .form__wrapper-button {
    @apply mt-3 col-span-12;
    button {
      @apply w-full;
    }
  }

  .none {
    @apply hidden;
  }

  .dialog__header {
    @apply relative;

    p {
      @apply px-9 pb-4 text-17_28_14_22;
      @apply sm:px-5 sm:pt-6;
    }
  }

  .dialog__content {
    @apply px-9;
  }

  .forgot-password__dialog {
    @apply w-[503px];
  }

  .dialog__close {
    @apply absolute top-3 right-3 rounded-full bg-white flex items-center justify-center w-9 h-9 border border-solid border-border transition-all duration-300 text-[16px];
    @apply sm:bg-dark-950 sm:text-white sm:top-3 sm:border-none;
    @apply hover:border-primary;

    i {
      @apply sm:font-light;
    }
  }

  input {
    &.input--invalid {
      @apply border-red-500;
    }
  }

  .form__wrapper-input {
    @apply col-span-12;

    span[role="alert"] {
      @apply text-13_18_11_14;
    }
  }

  .dialog__title {
    @apply font-fontText font-bold text-19_32_17_30 bg-white text-dark pb-3 px-9 pt-6 flex;
    @apply sm:bg-dark-950 sm:text-white sm:px-5 sm:py-4;
  }

  .dialog__content {
    @apply bg-white overscroll-contain px-9 pb-[39px] w-[503px];
    @apply lg:px-5;
    @apply sm:pb-24;
    @apply mob:col-span-12 mob:px-4;
    @apply xs:pb-20;
  }
</style>