export function appendLanguagePrefixURL(url) {

	let newUrl= null;
	try {
		newUrl = (new URL(url));
	} catch (e) {}

	if (newUrl !== null) {
		newUrl = newUrl.pathname + newUrl.search;
	} else {
		newUrl = url.startsWith('/') ? url : '/' + url;
	}

	const lang = fetchLanguage();
	const regex = new RegExp(`^${lang}`);

	return lang + newUrl.replace(regex, '');
}

export function appendLanguagePostfixURL(url) {
	const newUrl = url.replace(/\/$/, "");
	return newUrl + fetchLanguage();
}

export function fetchLanguage() {

	let language = '';

	switch (document.documentElement.lang.toLowerCase()) {
		case 'nl-be':
			language = '/nl-be';
			break;
		case 'fr':
			language = '/fr';
			break;
		default:
			language = ''
	}

	return language;
}