<script setup>
  import { ref } from "vue";
  import AddressesDialog from "../../components/Utility/Dialogs/Addresses.vue";
  import RemoveAddressDialog from "../../components/Utility/Dialogs/RemoveAddress.vue";
  import { useDialog } from "../../composables/useDialog.js";
  import { useProfileStore } from "../stores/mainStore";

  const props = defineProps({
    address: Object
  });

	const openEditAddressDialog = async (address) => {
		address.company_name = useProfileStore().accountData.contact_name || '';

    const isMobile = ref(false);

    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    useDialog().open(AddressesDialog, { address: address, fromDashboard: true, editMode: true, countries: useProfileStore().countries });
  };

	const openRemoveAddressDialog = async (address) => {
    const isMobile = ref(false);

    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    useDialog().open(RemoveAddressDialog, { address: address });
  };

	const setDefaultAddress = (type, address) => {

		let url = '';	
		if(type === 'shipping') {
    		url = '/api/address/'+ address.id + '/default_shipping';
		} else if(type === 'billing') {
    		url = '/api/address/'+ address.id + '/default_invoice';
		}

		fetch(url, {
			method: 'POST',
			body: JSON.stringify(props.address)
		})
		.then(response => response.json())
		.then(response => {
			if (response.success) {
				useProfileStore().updateDefaultAddress(type, address);
			} else {
				// TODO Handle error
			}
		})
		.catch(error => {
		// Handle error
		});
	}

  const closeModal = () => {
    useDialog.toggle();
  };
</script>

<template>
	<div class="dashboard-addresses__item">
		<span class="dashboard-adress--space-between">
			<strong>{{ address.title }}</strong>
			<span class="dashboard-address--default" v-if="address.is_shipping && address.is_billing"><i class="fa-regular fa-check"></i> Standaard bezorg- en factuuradres</span>  <!-- TO DO HARDCODED TEXT -->
			<span class="dashboard-address--default" v-if="address.is_shipping && !address.is_billing"><i class="fa-regular fa-check"></i> Standaard bezorgadres</span>  <!-- TO DO HARDCODED TEXT -->
			<span class="dashboard-address--default" v-if="!address.is_shipping && address.is_billing"><i class="fa-regular fa-check"></i> Standaard factuuradres</span>  <!-- TO DO HARDCODED TEXT -->
		</span>
        <span class="notranslate">{{ address.first_name }} {{ address.last_name }}</span>
		<span class="notranslate">{{ address.street }} {{ address.house_number }} {{ address.house_number_addition }}</span>
		<span class="notranslate">{{ address.zip }} {{ address.city }}</span>
		<span v-if="address.country" class="notranslate">{{ address.country }}</span>
		<div class="dashboard-adress__buttons" v-if="parseInt(address.id) > 9">
			<button class="btn-text--smedium--primary-600" @click="openEditAddressDialog(address)" @successEvent="closeModal">Wijzig</button>  <!-- TO DO HARDCODED TEXT -->
			<button class="btn-text--smedium--primary-600" @click="openRemoveAddressDialog(address)" @successEvent="closeModal" v-if="!address.is_shipping && !address.is_billing">Verwijder</button>  <!-- TO DO HARDCODED TEXT -->
			<button class="btn-text--smedium--primary-600" @click="setDefaultAddress('shipping', address)" v-if="!address.is_shipping">Stel in als bezorgadres</button>  <!-- TO DO HARDCODED TEXT -->
			<button class="btn-text--smedium--primary-600" @click="setDefaultAddress('billing', address)" v-if="!address.is_billing">Stel in als factuuradres</button>  <!-- TO DO HARDCODED TEXT -->
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.dashboard-addresses__item {
		@apply flex flex-col gap-1 py-7 px-11 border border-solid border-border rounded;
		@apply md:p-6;
		@apply sm:p-4;
		@apply xs:pb-1;
	}
	
	.dashboard-adress--space-between {
		@apply flex justify-between;
		@apply xs:flex-col xs:gap-1;
	}

	.dashboard-address--default {
    @apply text-16_20_14_18 flex gap-2 items-center;

    i {
      @apply text-[#4D8C4F];
    }
  }

	.dashboard-adress__buttons {
		@apply flex flex-wrap gap-x-5 gap-y-0 mt-2;
	}
</style>