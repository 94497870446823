<script setup>
import { useForm } from '@tanstack/vue-form';
import { ref } from 'vue'
import { useDialog } from "../../../composables/useDialog.js";
import ForgotPassword from "../../../components/Utility/Dialogs/ForgotPassword.vue";

const emit = defineEmits(['success']);

const continueWithoutLogin = () => {
  fetch('/api/checkout/login', {
    method: 'POST',
    body: JSON.stringify({
      continue_without_login: true
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        emit('success',true);
      } else {
        // handle failed
      }
    })
    .catch(error => {
      // Handle error
    });
}

const toggleForgotPasswordDialog = () => {
  useDialog().open(ForgotPassword);
}

const error = ref(null);

const form = useForm({
  initialValues: {
    username: '',
    password: ''
  },

  validators: {
    username: {
      onBlur: (value) => {
        if (!value) {
          return 'Dit veld is verplicht'
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return 'Dit is geen geldig e-mailadres'
        }
      }
    },
    password: {
      onBlur: ({ value }) => (!value ? 'Wachtwoord is verplicht' : undefined)
    }
  },
  onSubmit: async ({ value }) => {
    const data = new FormData();
    data.append('username', value.username);
    data.append('password', value.password);

    fetch('/api/checkout/login', {
    method: 'POST',
    body: data
  })
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        emit('success',true);
      } else {
        error.value = 'De combinatie van e-mailadres en wachtwoord is niet geldig.';
      }
    })
    .catch(error => {
      // Handle error
    });
  }
});
</script>

<template>
    <div class="form__wrapper">
      <div class="form__wrapper-login">
        <span class="form__wrapper-title">Inloggen</span>
        <p>Heb je al een account? Log dan in en bestel gemakkelijk en snel met jouw beschikbare gegevens.</p>

        <form @submit="
        (e) => {
          e.stopPropagation()
          e.preventDefault()
          form.handleSubmit()
        }
        "
        method="post"
        > 
          <div class="form__wrapper-input form__wrapper-input--user">
            <form.Field name="username">
              <template v-slot="{ field }">
                <input
                  :name="field.name"
                  :value="field.state.value"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  type="email"
                  placeholder="E-mailadres"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="form__wrapper-input form__wrapper-input--password">
            <form.Field name="password">
              <!-- TODO: hardcoded -->
              <template v-slot="{ field }">
                <input
                  :name="field.name"
                  :value="field.state.value"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  type="password"
                  placeholder="Wachtwoord"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                  required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            <span v-if="error" role="alert">{{ error }}</span>
          </div>
          <div class="form__wrapper-buttons">
            <div class="form__wrapper-link">
              <!-- Call to wachtwoord vergeten -->
              <button @click.prevent="toggleForgotPasswordDialog" class="btn-text--primary-600">Wachtwoord vergeten?</button>
            </div>

            <div class="form__wrapper-button">
              <button type="submit" class="btn-solid--primary-600">Inloggen</button>
            </div>
          </div>
        </form>
      </div>

      <div class="form__wrapper-registration">
        <span class="form__wrapper-title">Nieuw bij Maunt?</span>
        <p>Het is bij Maunt ook mogelijk om te bestellen zonder account of deze aan te maken tijdens het bestelproces.</p>

        <a @click.prevent="continueWithoutLogin" class="btn-solid--primary-600">Door naar gegevens invullen</a>
      </div>
    </div>
</template>

<style lang="scss" scoped>
.form__wrapper{
  @apply grid grid-cols-12 gap-0 col-span-12;

  > div {
    &:first-child {
      @apply pr-14;
      @apply lg:pr-6;
      @apply md:pr-0;

      + div {
        @apply pl-14 border-l border-solid border-border;
        @apply lg:pl-6;
        @apply md:pl-0 md:border-0 md:mt-[30px];
      }
    }
  }
}

.form__wrapper-login {
  @apply col-span-5 col-start-2 text-17_28_14_20;
  @apply lg:col-span-6;
  @apply md:col-span-12;

  form {
    @apply mt-4 flex flex-col;
    @apply mob:mt-2;
  }
}

.form__wrapper-registration {
  @apply col-span-5 col-start-7 text-17_28_14_20;
  @apply lg:col-span-6;
  @apply md:col-span-12;

  .btn-solid--primary-600 {
    @apply mt-5;
    @apply mob:mt-3 mob:w-full;
  }
}

.form__wrapper-title {
  @apply text-24_30_17_20 font-bold mb-2.5 block;
  @apply mob:mb-1.5;
}

.form__wrapper-input {
  @apply mt-3;
  @apply mob:mt-1;

  span[role="alert"] {
    @apply text-13_18_11_14 text-red-500;
  }

  input {
    &.input--invalid {
      @apply border-red-500;
    }
  }
}

.form__wrapper-input--password {
  @apply relative;

  input {
    @apply pr-[100px]; 
  }
}

.form__wrapper-switcher {
  @apply text-primary-600 absolute inset-y-0 right-4 flex items-center cursor-pointer text-15_20_14_20;
  @apply hover:text-primary-700;
  @apply mob:right-2;
}

.form__wrapper-buttons {
  @apply flex flex-wrap justify-between mt-6 items-center gap-3;
  @apply sm:flex-col sm:items-start;
  @apply xs:gap-0 xs:mt-0;
}

.btn-text--primary-600 {
  @apply font-normal text-17_30_14_22;
}

.form__wrapper-button {
  @apply w-1/2;
  @apply lg:w-auto;
  @apply mob:w-full;

  > button {
    @apply w-full;
  }
}
</style>
