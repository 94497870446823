<script setup>
  import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

  import { useRoute } from 'vue-router';

  import { useDropdowns } from '../composables/useDropdowns';
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const { isCartOpen, isCartWideScreen, isUserOpen, isUserWideScreen } = useDropdowns();

  const route = useRoute();

  const props = defineProps({
    isLoggedIn: Number,
    userFirstName: String
  });

  const loggedIn = ref(props.isLoggedIn === 1);

  const clickedOnRouterLink = () => {
    isUserOpen.value = false;
    document.querySelector("body").classList.remove("user-nav--open");
    document.querySelector("[data-id='user-nav-closer']")?.setAttribute('aria-expanded', 'false');
    document.querySelector("[data-id='user-nav-toggle']")?.setAttribute('aria-expanded', 'false');
  }

  const isParentActive = computed(() => {
    return route.name === 'Bestellingen' || route.name === 'Bestelling';
  });
</script>

<template>
  
  <span class="nav__elements--loggedin" :class="{'nav__elements--loggedin--open': isUserOpen && isUserWideScreen}" v-if="loggedIn">
    <button class="nav__elements--profile widescreen" v-if="isUserWideScreen" data-id="user-nav-toggle" @click="isCartOpen = false">
      <span class="nav__elements--text">{{ userFirstName ? userFirstName : 'Welkom'}}</span
      ><i class="fa-light fa-user"></i>
      <span class="check-icon"><i class="fa-solid fa-check"></i></span>
    </button>
    <button class="nav__elements--profile widescreen-no" v-else data-id="user-nav-toggle">
      <span class="nav__elements--text">{{ userFirstName ? userFirstName : 'Welkom'}}</span
      ><i class="fa-light fa-user"></i>
      <span class="check-icon"><i class="fa-solid fa-check"></i></span>
    </button>
    <!-- TO DO HARDCODED TEXT -->
    <div class="nav__elements--dropdown" v-if="useRoute().fullPath.includes('/dashboard')">
    	<span class="nav__elements--dropdown-title">Welkom, {{userFirstName ? userFirstName : ''}}</span>
			<div>
        <router-link :to="{ name: 'Overzicht' }">Overzicht</router-link>
      </div>
			<div>
        <router-link :to="{ name: 'Bestellingen' }" :class="{ 'active': isParentActive }">Bestellingen</router-link>
      </div>
			<div>
        <router-link :to="{ name: 'Service Afspraken' }">Service afspraken</router-link>
      </div>
			<div>
        <router-link :to="{ name: 'Mijn Apparaten' }">Mijn apparaten</router-link>
      </div>
			<div>
        <router-link :to="{ name: 'Gegevens' }">Gegevens</router-link>
      </div>
			<div>
        <router-link :to="{ name: 'Adressen' }">Adressen</router-link>
      </div>
      <div>
        <a :href="appendLanguagePrefixURL('/logout')">Uitloggen</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
    </div>
    <div class="nav__elements--dropdown" v-else>
      <span class="nav__elements--dropdown-title">Welkom, {{ userFirstName ? userFirstName : "" }}</span>
      <div>
        <a :href="appendLanguagePrefixURL('/dashboard/overzicht')">Overzicht</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
      <div>
        <a :href="appendLanguagePrefixURL('/dashboard/bestellingen')" :class="{ 'active': isParentActive }">Bestellingen</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
      <div>
        <a :href="appendLanguagePrefixURL('/dashboard/service_afspraken')">Service afspraken</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
      <div>
        <a :href="appendLanguagePrefixURL('/dashboard/mijn_apparaten')">Mijn apparaten</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
      <div>
        <a :href="appendLanguagePrefixURL('/dashboard/gegevens')">Gegevens</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
      <div>
        <a :href="appendLanguagePrefixURL('/dashboard/adressen')">Adressen</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
      <div>
        <a :href="appendLanguagePrefixURL('/logout')">Uitloggen</a> <!-- TO DO HARDCODED TEXT & LINK -->
      </div>
    </div>
    <div class="nav__mobile-menu nav__user-mobile-menu">
      <div class="nav__mobile-head">
        <span class="nav__mobile-title">Mijn Maunt</span>
        <div
          class="nav__mobile-closer"
          data-id="user-nav-closer"
          aria-expanded="false"
          aria-controls="mobile-menu"
        >
          <i class="fa-light fa-xmark"></i>
        </div>
      </div>
      <div class="nav__mobile-content">
          <nav>
              <ul class="nav__items" v-if="useRoute().fullPath.includes('/dashboard')">
                  <li class="nav__item" @click="clickedOnRouterLink();"><router-link :to="{ name: 'Overzicht' }">Overzicht</router-link></li>
                  <li class="nav__item" @click="clickedOnRouterLink();"><router-link :to="{ name: 'Bestellingen' }">Bestellingen</router-link></li>
                  <li class="nav__item" @click="clickedOnRouterLink();"><router-link :to="{ name: 'Service Afspraken' }">Service afspraken</router-link></li>
                  <li class="nav__item" @click="clickedOnRouterLink();"><router-link :to="{ name: 'Mijn Apparaten' }">Mijn apparaten</router-link></li>
                  <li class="nav__item" @click="clickedOnRouterLink();"><router-link :to="{ name: 'Gegevens' }">Gegevens</router-link></li>
                  <li class="nav__item" @click="clickedOnRouterLink();"><router-link :to="{ name: 'Adressen' }">Adressen</router-link></li>
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/logout')">Uitloggen</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
              </ul>
              <ul class="nav__items" v-else>
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/dashboard/overzicht')">Overzicht</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/dashboard/bestellingen')">Bestellingen</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/dashboard/service_afspraken')">Service afspraken</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/dashboard/mijn_apparaten')">Mijn apparaten</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/dashboard/gegevens')">Gegevens</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/dashboard/adressen')">Adressen</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
                  <li class="nav__item"><a :href="appendLanguagePrefixURL('/logout')">Uitloggen</a></li> <!-- TO DO HARDCODED TEXT & LINK -->
              </ul>
          </nav>
    	</div>
		</div>
  </span>
  <a class="nav__elements--profile" :href="appendLanguagePrefixURL('/login')" v-else><span class="nav__elements--text">Mijn Maunt</span><i class="fa-light fa-user-lock"></i></a>  <!-- TO DO HARDCODED TEXT -->
</template>


<style lang="scss" scoped>
.nav__elements--loggedin {
  @apply relative h-full inline-flex items-center;

  > button {
    @apply relative text-white px-4 h-full inline-flex items-center cursor-pointer transition-all duration-300;
    @apply hover:text-primary;
    @apply lg:px-0;

    &::after {
      @apply content-[""] absolute inset-x-4 bottom-0 h-[3px] bg-primary-600 transition-all duration-300 opacity-0;
      @apply lg:inset-x-0;
    }
  }

  @media screen and (min-width: 1225px) {

    &.nav__elements--loggedin--open {
      > button::after {
        @apply opacity-100;
      }

      .nav__elements--dropdown {
        @apply opacity-100 pointer-events-auto;
      }
    }
  }
}

.check-icon {
  @apply content-[""] absolute rounded-full h-[20px] w-[20px] bg-primary-600 flex items-center justify-center right-0 top-5;
  @apply lg:top-3 lg:h-[16px] lg:w-[16px] lg:text-[9px] lg:right-[3px];

  i {
    @apply text-[10px] text-white;
  }
}

.nav__elements--dropdown {
  @apply absolute top-full right-4 shadow-card py-7 flex flex-col z-50 w-[294px] bg-white rounded opacity-0 pointer-events-none transition-all duration-200 rounded-tr-none;

  .nav__elements--dropdown-title {
    @apply font-bold text-19_32_20_30 pb-5 px-9;
  }

  > div > a {
    @apply relative text-17_21_14_21 py-2 px-9 flex items-center justify-start;
    @apply hover:text-primary;

    &::before {
      @apply content-[""] absolute left-0 inset-y-0 w-0.5 bg-primary-600 opacity-0 transition-all duration-300;
    }

    &.active,
    &.router-link-active {
      @apply bg-[#FAF2E6] font-bold;

      &::before {
        @apply opacity-100;
      }
    }
  }
}

@media screen and (max-width: 1224px) {
  .nav--open {
    .nav__mobile-menu {
      &::before {
        @apply opacity-0;
      }
    }
  }


  .user-nav--open {
    @apply overflow-hidden;

    .nav__mobile-menu {
      @apply left-0;

      &::before {
        @apply opacity-100;
      }
    }
  }
}
</style>
