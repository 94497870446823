<script setup>
  import { ref, onMounted } from "vue";
  import { useDialog } from "../../../composables/useDialog";
  import editNewAddressForm from "../../../layouts/Checkout/Address/EditNewAddress.vue";

  const props = defineProps({
    data: {},
    address: {},
  });

  const addressesData = ref(props.data);

  const editMode = ref(false);

  const editAddress = ref({});
  const newAddress = ref(false);

  const fromDashboard = ref(false);

  const setAddress = (id, type) => {
    props.data.cartAddresses[type] = {"id" : id};
  }

  const openEditAddressesDialog = async (address) => {
    const isMobile = ref(false);
    
    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    editMode.value = true;
    editAddress.value = address;
  };

  const openNewAddresDialog = async () => {
    const isMobile = ref(false);
    
    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    editMode.value = false;
    newAddress.value = true;
  };

  const closeModal = () => {
    useDialog().toggle();
    editMode.value = false;
    newAddress.value = false;
    editAddress.value = {};
  };

  const cancelEditAddress = () => {
    editMode.value = false;
    newAddress.value = false;
    editAddress.value = {};
    if(fromDashboard) {
      useDialog().toggle();
    }
  }  

  onMounted(() => {
    if(props.data?.fromDashboard) {
      fromDashboard.value = props.data.fromDashboard;
      editAddress.value = props.data.address;
      editMode.value = props.data.editMode; 
      newAddress.value = props.data.newAddress; 
    }
  });

  const findCountryById = (country_id) => {
    return props.data.countries.find((country) => country.id == country_id);
  }  
</script>

<template>
    <div class="dialog__header">
      <span class="dialog__title" v-if="editMode">Wijzig adres</span> <!-- TO DO HARDCODED TEXT -->
      <span class="dialog__title" v-else-if="newAddress">Voeg nieuw adres toe</span> <!-- TO DO HARDCODED TEXT -->
      <span class="dialog__title" v-else-if="!editMode && !newAddress">Kies een bezorgadres</span> <!-- TO DO HARDCODED TEXT -->
      <button class="dialog__close" @click="useDialog().toggle">
        <i class="fa-solid fa-times"></i>
      </button>
    </div>
    <div class="dialog__content" @scroll="checkDialogPosition()">
        <div v-if="!editMode && !newAddress && props.data?.addresses">
          <div class="dialog__addresses">
            <div v-for="(address, index) in props.data.addresses" :key="index">
              <div class="address__content"
                    @click="setAddress(address.id, 'shipping')"
                    :class="{'address__content--active': address.id === addressesData?.cartAddresses?.shipping?.id}"
              >
                <div class="address__inner">
                  <div>
                    <span><strong class="notranslate" v-if="address.title">{{ address.title }}</strong></span>
                    <span class="notranslate">{{ address.street }} {{ address.house_number }} {{ address.house_number_addition }}</span>
                    <span class="notranslate">{{ address.zip }} {{ address.city }}</span>
                    <span v-if="address?.country_id">{{ findCountryById(address.country_id).name }}</span>
                    <span v-else-if="address?.country">{{ address.country }}</span>
                    <span class="address--default" v-if="address.is_shipping && address.is_billing"><i class="fa-regular fa-check"></i> Standaard bezorg- en factuuradres</span>  <!-- TO DO HARDCODED TEXT -->
                    <span class="address--default" v-if="address.is_shipping && !address.is_billing"><i class="fa-regular fa-check"></i> Standaard bezorgadres</span>  <!-- TO DO HARDCODED TEXT -->
                    <span class="address--default" v-if="!address.is_shipping && address.is_billing"><i class="fa-regular fa-check"></i> Standaard factuuradres</span>  <!-- TO DO HARDCODED TEXT -->
                  </div>
                  <div>
                    <span class="address__edit btn-text--primary-600" @click="openEditAddressesDialog(address)">Wijzig</span> <!-- HARDCODED TEXT -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog__footer">
            <button class="btn-transp--border" @click="openNewAddresDialog()">Nieuw adres toevoegen</button> <!-- TO DO HARDCODED TEXT -->
          </div>
        </div>
        <div class="dialog__edit-address" v-else-if="editMode">
          <editNewAddressForm :editMode="editMode" :from-dashboard="fromDashboard" :countries="props.data.countries" @successEvent="closeModal()" @cancelEditAddress="cancelEditAddress()" :address="editAddress" />
        </div>
        <div class="dialog__edit-address" v-else-if="newAddress">
          <editNewAddressForm :editMode="editMode" :from-dashboard="fromDashboard" :countries="props.data.countries" @successEvent="closeModal()" @cancelEditAddress="cancelEditAddress()" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
dialog {
  &[open] {
    @apply container grid grid-cols-12 gap-6 bg-transparent h-[90vh] p-0;
    @apply lg:px-6;
    @apply sm:px-4 sm:h-screen;
    @apply xs:p-0 xs:m-0 xs:max-w-full xs:h-full xs:max-h-full;

    &:focus-within {
      @apply outline-none;
    }

    &::backdrop {
      @apply bg-black/60;
      backdrop-filter: blur(2px);
    }
  }
}

.dialog__header {
  @apply relative;
}

.dialog__close {
  @apply absolute top-3 right-3 rounded-full bg-white flex items-center justify-center w-9 h-9 border border-solid border-border transition-all duration-300 text-[16px];
  @apply sm:bg-dark-950 sm:text-white sm:top-3 sm:border-none;
  @apply hover:border-primary;

  i {
    @apply sm:font-light;
  }
}

.dialog__title {
  @apply font-fontText font-bold text-19_32_17_30 bg-white text-dark pb-3 px-9 pt-6 flex;
  @apply sm:bg-dark-950 sm:text-white sm:px-5 sm:py-4;
}

.dialog__content {
  @apply bg-white overscroll-contain px-9 pb-[39px];
  @apply lg:px-5 lg:pt-[30px];
  @apply sm:pb-24;
  @apply mob:col-span-12 mob:px-4;
  @apply xs:pb-20;
}

.address__inner {
  @apply flex flex-row;
  @apply sm:flex-col;

  > div {
    @apply flex flex-col;

    + div {
      @apply ml-auto;
      @apply sm:ml-0;
    }
  }
}

.address__inner--flex {
  @apply flex w-full justify-between;
}

.dialog__addresses {
  @apply flex flex-col gap-3;
  @apply sm:pb-[100px];
}

.address__content {
  @apply bg-white py-7 px-10 rounded border border-solid border-border transition-all duration-300 cursor-pointer h-full w-[500px] text-16_28_14_22;
  @apply sm:w-[273px] sm:p-4;
  @apply hover:border-[#CCA666];

  &--active {
    @apply bg-[#FAF2E6] border-[#CCA666];

    .address__edit {
      @apply opacity-100;
      @apply sm:inline-flex;
    }
  }
}

.address__edit {
  @apply font-normal text-16_24_14_18 opacity-0 transition-all duration-300 ml-0;
  @apply sm:mt-2 sm:hidden;
}

.dialog__edit-address {
  @apply w-[812px];
  @apply sm:w-[273px];
}

.address--default {
  @apply text-16_20_14_18 flex gap-2 items-center mt-3;

  i {
    @apply text-[#4D8C4F];
  }
}

.dialog__footer {
  @apply flex justify-end bg-white pt-3 gap-3;
  @apply sm:fixed sm:inset-x-0 sm:bottom-0 sm:px-4 sm:py-3 sm:shadow-card;

  button {
    @apply w-full flex;
  }
}

</style>
